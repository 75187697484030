import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActionsBar, DialogCloseEvent } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { filterIcon } from '@progress/kendo-svg-icons';
import { FieldSettings, Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

import styles from './KendoGridFilter.module.scss';

const DEFAULT_FILTER: CompositeFilterDescriptor = { logic: 'or', filters: [] };

interface IProps {
  fields: FieldSettings[];
  initialFilter?: CompositeFilterDescriptor;
  onFilterChanged?: (filter?: CompositeFilterDescriptor) => void;
}

export const KendoGridFilter: FC<IProps> = ({ fields, initialFilter, onFilterChanged }) => {
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>(DEFAULT_FILTER);

  const openDialog = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    setFilter(initialFilter || DEFAULT_FILTER);

    setVisibleDialog(true);
  };

  const closeDialog = (e: React.MouseEvent<HTMLElement> | DialogCloseEvent) => {
    const event = e as any;
    event.preventDefault && event.preventDefault();

    setVisibleDialog(false);
  };

  const onSubmit = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      const normalizedFilter = filter ? removeEmptyFilters(filter) : undefined;
      onFilterChanged && onFilterChanged(normalizedFilter);
      setVisibleDialog(false);
    },
    [filter, onFilterChanged],
  );

  const onFilterChange = (event: FilterChangeEvent) => {
    setFilter(event.filter);
  };

  return (
    <>
      <Button svgIcon={filterIcon} onClick={openDialog}>
        Filter
      </Button>

      {visibleDialog && (
        <Dialog title='Filter' onClose={closeDialog} className={styles.dialog}>
          <div className={`form-card  ${styles.formCard}`}>
            <Filter value={filter} onChange={onFilterChange} fields={fields} />

            <DialogActionsBar layout='end'>
              <Button className='k-button-solid-primary k-rounded-md' onClick={onSubmit}>
                <span className='k-button-text'>Apply</span>
              </Button>

              <button className='k-button k-button-md k-button-solid k-rounded-md' onClick={closeDialog}>
                <span className='k-button-text'>Cancel</span>
              </button>
            </DialogActionsBar>
          </div>
        </Dialog>
      )}
    </>
  );
};

function removeEmptyFilters(filter: CompositeFilterDescriptor): CompositeFilterDescriptor {
  const compositeFilter = filter as CompositeFilterDescriptor;

  const filters: Array<FilterDescriptor | CompositeFilterDescriptor> = [];

  compositeFilter.filters.forEach((x) => {
    if (isFilterDescriptor(x)) {
      return filters.push(x);
    }

    const composite = x as CompositeFilterDescriptor;
    composite.filters = composite.filters.filter((f) => {
      if (isFilterDescriptor(f)) {
        return true;
      }

      return removeEmptyFilters(f as CompositeFilterDescriptor)?.filters?.length > 0;
    });

    if (composite.filters.length > 0) {
      filters.push(composite);
    }
  });

  compositeFilter.filters = filters;

  return compositeFilter;
}

function isFilterDescriptor(filter: FilterDescriptor | CompositeFilterDescriptor): boolean {
  if ((filter as FilterDescriptor)?.operator) {
    return true;
  }

  return false;
}
