import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OccupancySource, WeekDay } from '../../../../model';
import { IOccupancyHeatmapData, IOffstreetZoneOccupancyState } from '../../../../services';
import { RootState } from '../../../../store';

export interface IOccupancyState {
  meterOccupancyData: IOccupancyHeatmapData[];
  meterOccupancyDataLoading: boolean;
  zoneOccupancyData: IOccupancyHeatmapData[];
  zoneOccupancyDataLoading: boolean;
  offstreetZoneOccupancyData: IOccupancyHeatmapData[];
  offstreetZoneOccupancyDataLoading: boolean;
  blockfaceOccupancyData: IOccupancyHeatmapData[];
  blockfaceOccupancyDataLoading: boolean;
  studyAreaOccupancyData: IOccupancyHeatmapData[];
  studyAreaOccupancyDataLoading: boolean;
  spotOccupancyData: IOccupancyHeatmapData[];
  spotOccupancyDataLoading: boolean;
  offstreetZoneOccupancyState: IOffstreetZoneOccupancyState[];
  offstreetZoneOccupancyStateLoading: boolean;
}

const initialState: IOccupancyState = {
  meterOccupancyData: [],
  meterOccupancyDataLoading: false,

  zoneOccupancyData: [],
  zoneOccupancyDataLoading: false,

  offstreetZoneOccupancyData: [],
  offstreetZoneOccupancyDataLoading: false,

  blockfaceOccupancyData: [],
  blockfaceOccupancyDataLoading: false,

  studyAreaOccupancyData: [],
  studyAreaOccupancyDataLoading: false,

  spotOccupancyData: [],
  spotOccupancyDataLoading: false,

  offstreetZoneOccupancyState: [],
  offstreetZoneOccupancyStateLoading: false,
};

const slice = createSlice({
  name: 'occupancyData',
  initialState: initialState,
  reducers: {
    fetchMeterOccupancyData(
      state,
      action: PayloadAction<{
        occupancySource: OccupancySource;
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.meterOccupancyDataLoading = true;
    },
    fetchMeterOccupancyDataSuccess(state, action: PayloadAction<IOccupancyHeatmapData[]>) {
      state.meterOccupancyData = action.payload;
      state.meterOccupancyDataLoading = false;
    },
    fetchMeterOccupancyDataFailed(state, action: PayloadAction<string>) {
      state.meterOccupancyDataLoading = false;
      console.error(action);
    },

    fetchZoneOccupancyData(
      state,
      action: PayloadAction<{
        occupancySource: OccupancySource;
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.zoneOccupancyDataLoading = true;
    },
    fetchZoneOccupancyDataSuccess(state, action: PayloadAction<IOccupancyHeatmapData[]>) {
      state.zoneOccupancyData = action.payload;
      state.zoneOccupancyDataLoading = false;
    },
    fetchZoneOccupancyDataFailed(state, action: PayloadAction<string>) {
      console.error(action);
      state.zoneOccupancyDataLoading = false;
    },


    fetchOffstreetZoneOccupancyData(
      state,
      action: PayloadAction<{
        occupancySource: OccupancySource;
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.offstreetZoneOccupancyDataLoading = true;
    },
    fetchOffstreetZoneOccupancyDataSuccess(state, action: PayloadAction<IOccupancyHeatmapData[]>) {
      state.offstreetZoneOccupancyData = action.payload;
      state.offstreetZoneOccupancyDataLoading = false;
    },
    fetchOffstreetZoneOccupancyDataFailed(state, action: PayloadAction<string>) {
      console.error(action);
      state.offstreetZoneOccupancyDataLoading = false;
    },

    fetchOffstreetZoneOccupancyState(
      state,
      action: PayloadAction,
    ) {
      state.offstreetZoneOccupancyStateLoading = true;
    },
    fetchOffstreetZoneOccupancyStateSuccess(state, action: PayloadAction<IOffstreetZoneOccupancyState[]>) {
      state.offstreetZoneOccupancyState = action.payload;
      state.offstreetZoneOccupancyStateLoading = false;
    },
    fetchOffstreetZoneOccupancyStateFailed(state, action: PayloadAction<string>) {
      console.error(action);
      state.offstreetZoneOccupancyStateLoading = false;
    },

    fetchBlockfaceOccupancyData(
      state,
      action: PayloadAction<{
        occupancySource: OccupancySource;
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.blockfaceOccupancyDataLoading = true;
    },
    fetchBlockfaceOccupancyDataSuccess(state, action: PayloadAction<IOccupancyHeatmapData[]>) {
      state.blockfaceOccupancyData = action.payload;
      state.blockfaceOccupancyDataLoading = false;
    },
    fetchBlockfaceOccupancyDataFailed(state, action: PayloadAction<string>) {
      console.error(action);
      state.blockfaceOccupancyDataLoading = false;
    },

    fetchStudyAreaOccupancyData(
      state,
      action: PayloadAction<{
        occupancySource: OccupancySource;
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.studyAreaOccupancyDataLoading = true;
    },
    fetchStudyAreaOccupancyDataSuccess(state, action: PayloadAction<IOccupancyHeatmapData[]>) {
      state.studyAreaOccupancyData = action.payload;
      state.studyAreaOccupancyDataLoading = false;
    },
    fetchStudyAreaOccupancyDataFailed(state, action: PayloadAction<string>) {
      console.error(action);
      state.studyAreaOccupancyDataLoading = false;
    },

    fetchSpotOccupancyData(
      state,
      action: PayloadAction<{
        occupancySource: OccupancySource;
        period: [Date, Date];
        weekDays: WeekDay[];
        minutesStart: number;
        minutesEnd: number;
      }>,
    ) {
      state.spotOccupancyDataLoading = true;
    },
    fetchSpotOccupancyDataSuccess(state, action: PayloadAction<IOccupancyHeatmapData[]>) {
      state.spotOccupancyData = action.payload;
      state.spotOccupancyDataLoading = false;
    },
    fetchSpotOccupancyDataFailed(state, action: PayloadAction<string>) {
      console.error(action);
      state.spotOccupancyDataLoading = false;
    },
  },
});

// Actions
export const occupancyDataActions = slice.actions;

// Selectors
export const selectOccupancyData = (state: RootState) => state.occupancyData;

// Reducer
export const occupancyDataReducer = slice.reducer;
