import { FC } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';

export const CheckBoxCell: FC<GridCellProps> = (props) => {
  return <td>{props.dataItem && props.field && <Checkbox disabled={true} checked={props.dataItem[props.field]} />}</td>;
};

export const EmptyCell = '—';

export const GetCellWithDefaultValue = (condition: boolean, content: () => JSX.Element) => {
  if (!condition) return (<td>{EmptyCell}</td>);

  return (<td>{content()}</td>);
};

export const GetStringCellWithDefaultValue = (condition: boolean, content: () => string) => {
  if (!condition) return (<td>{EmptyCell}</td>);

  return (<td>{content()}</td>);
};

export const CellWithDefaultValue = (props: GridCellProps) => {
  const value = !!props.field && props.dataItem[props.field];
  if (!value) return (<td>{EmptyCell}</td>);
  return (
    <td>
      {value}
    </td>
  );
};