import { Action } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { from, Observable, of, pairwise } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { occupancyData } from '../../../../services';
import { RootState } from '../../../../store';
import { offstreetZonesLayerActions } from '../offstreet-zones/offstreet-zones-layer-slice';
import { occupancyDataActions } from './occupancy-slice';

const fetchMeterOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchMeterOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getMeterOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchMeterOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchMeterOccupancyDataFailed(err.message))),
      ),
    ),
  );

const fetchZoneOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchZoneOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getZoneOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchZoneOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchZoneOccupancyDataFailed(err.message))),
      ),
    ),
  );


const fetchOffstreetZoneOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchOffstreetZoneOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getOffstreetZoneOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchOffstreetZoneOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchOffstreetZoneOccupancyDataFailed(err.message))),
      ),
    ),
  );


const fetchOffstreetZoneOccupancyStateEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  const statePairs$ = state$.pipe(pairwise());

  return actions$.pipe(
    withLatestFrom(statePairs$),
    filter(([action, [oldState, newState]]) =>
      (offstreetZonesLayerActions.setEnabled.match(action) && action.payload === true && oldState.offstreetZonesLayer.enabled === false)
      || (offstreetZonesLayerActions.applySavedLayerSettings.match(action) && action.payload?.enabled === true)
    ),
    switchMap((action) =>
      from(
        occupancyData.getOffstreetZoneOccupancyState(),
      ).pipe(
        map((x) => occupancyDataActions.fetchOffstreetZoneOccupancyStateSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchOffstreetZoneOccupancyStateFailed(err.message))),
      ),
    ),
  );
}

const fetchBlockfaceOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchBlockfaceOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getBlockfaceOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchBlockfaceOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchBlockfaceOccupancyDataFailed(err.message))),
      ),
    ),
  );

const fetchStudyAreaOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchStudyAreaOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getStudyAreaOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchStudyAreaOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchStudyAreaOccupancyDataFailed(err.message))),
      ),
    ),
  );

const fetchSpotOccupancyDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(occupancyDataActions.fetchSpotOccupancyData.match),
    switchMap((action) =>
      from(
        occupancyData.getSpotOccupancyHeatmapData(
          action.payload.occupancySource,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => occupancyDataActions.fetchSpotOccupancyDataSuccess(x)),
        catchError((err) => of(occupancyDataActions.fetchSpotOccupancyDataFailed(err.message))),
      ),
    ),
  );

export const occupancyDataEpic = combineEpics(
  fetchMeterOccupancyDataEpic,
  fetchZoneOccupancyDataEpic,
  fetchOffstreetZoneOccupancyDataEpic,
  fetchBlockfaceOccupancyDataEpic,
  fetchStudyAreaOccupancyDataEpic,
  fetchSpotOccupancyDataEpic,
  fetchOffstreetZoneOccupancyStateEpic
);
