import { IEntityBase } from './entity-base';

export enum CameraStatus {
  Offline = 'Offline',
  Inactive = 'Inactive',
  Active = 'Active',
}

export enum AiVideoCameraStatus {
  Ok = 'ok',
  Error = 'error',
}

export interface ICameraConfiguration {
  Mode: string;
  UploadIntervalMs: number;
  TelemetryTimeoutMs: number;
  Version: string;
  BootTime: Array<{ From: string; To: string }>;
}

export interface ICameraWithSpots {
  CameraId: string;
  SpotIds: Array<number>;
}

export interface ICamera extends IEntityBase<string> {
  Name: string;
  Position: [number, number];
  Address: string;
  SpotIds: Array<number>;
  ZoneIds: Array<number>;
  OffstreetZoneIds: Array<number>;
  CreatedAt: Date;
  UpdatedAt: Date;
  VideoUrl: string;
  Configuration?: ICameraConfiguration;
}

export interface ICameraState {
  CameraId: string;
  Name: string;
  Status: CameraStatus;
}

export interface ICameraStatusCount {
  Status: CameraStatus;
  Count: number;
}

export interface IAiVideoCameraStatusInfo {
  Status: AiVideoCameraStatus;
  LastFrameTime?: Date;
}

export function createInactiveCameraState(cameraId: string): ICameraState {
  return {
    CameraId: cameraId,
    Name: '',
    Status: CameraStatus.Inactive,
  };
}
