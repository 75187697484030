import { useEffect, useState } from 'react';
import { UserClass } from '../../model';
import { useExtendedLocalization } from '../use-extended-localization-service';

export const useUserClasses = () => {
  const localization = useExtendedLocalization();
  const [items, setItems] = useState<Array<{ id: UserClass; name: string; group: string }>>([]);

  useEffect(() => {
    setItems([
      { id: UserClass.Bicycle, name: localization.toLanguageStringF('parkingPolicy.class.Bicycle'), group: 'Vehicle Types' },
      { id: UserClass.Bus, name: localization.toLanguageStringF('parkingPolicy.class.Bus'), group: 'Vehicle Types' },
      { id: UserClass.CargoBicycle, name: localization.toLanguageStringF('parkingPolicy.class.CargoBicycle'), group: 'Vehicle Types' },
      { id: UserClass.Car, name: localization.toLanguageStringF('parkingPolicy.class.Car'), group: 'Vehicle Types' },
      { id: UserClass.Moped, name: localization.toLanguageStringF('parkingPolicy.class.Moped'), group: 'Vehicle Types' },
      { id: UserClass.Motorcycle, name: localization.toLanguageStringF('parkingPolicy.class.Motorcycle'), group: 'Vehicle Types' },
      { id: UserClass.Scooter, name: localization.toLanguageStringF('parkingPolicy.class.Scooter'), group: 'Vehicle Types' },
      { id: UserClass.Truck, name: localization.toLanguageStringF('parkingPolicy.class.Truck'), group: 'Vehicle Types' },
      { id: UserClass.Van, name: localization.toLanguageStringF('parkingPolicy.class.Van'), group: 'Vehicle Types' },

      { id: UserClass.Autonomous, name: localization.toLanguageStringF('parkingPolicy.class.Autonomous'), group: 'Vehicle Properties' },
      { id: UserClass.Combustion, name: localization.toLanguageStringF('parkingPolicy.class.Combustion'), group: 'Vehicle Properties' },
      { id: UserClass.Electric, name: localization.toLanguageStringF('parkingPolicy.class.Electric'), group: 'Vehicle Properties' },
      {
        id: UserClass.ElectricAssist,
        name: localization.toLanguageStringF('parkingPolicy.class.ElectricAssist'),
        group: 'Vehicle Properties',
      },
      {
        id: UserClass.HandicapAccessible,
        name: localization.toLanguageStringF('parkingPolicy.class.HandicapAccessible'),
        group: 'Vehicle Properties',
      },
      { id: UserClass.Human, name: localization.toLanguageStringF('parkingPolicy.class.Human'), group: 'Vehicle Properties' },

      { id: UserClass.Construction, name: localization.toLanguageStringF('parkingPolicy.class.Construction'), group: 'Purpose' },
      { id: UserClass.Delivery, name: localization.toLanguageStringF('parkingPolicy.class.Delivery'), group: 'Purpose' },
      {
        id: UserClass.DisabledParkingPermit,
        name: localization.toLanguageStringF('parkingPolicy.class.DisabledParkingPermit'),
        group: 'Purpose',
      },
      { id: UserClass.EmergencyUse, name: localization.toLanguageStringF('parkingPolicy.class.EmergencyUse'), group: 'Purpose' },
      { id: UserClass.Freight, name: localization.toLanguageStringF('parkingPolicy.class.Freight'), group: 'Purpose' },
      { id: UserClass.Parking, name: localization.toLanguageStringF('parkingPolicy.class.Parking'), group: 'Purpose' },
      { id: UserClass.Permit, name: localization.toLanguageStringF('parkingPolicy.class.Permit'), group: 'Purpose' },
      { id: UserClass.Rideshare, name: localization.toLanguageStringF('parkingPolicy.class.Rideshare'), group: 'Purpose' },
      { id: UserClass.School, name: localization.toLanguageStringF('parkingPolicy.class.School'), group: 'Purpose' },
      { id: UserClass.ServiceVehicles, name: localization.toLanguageStringF('parkingPolicy.class.ServiceVehicles'), group: 'Purpose' },
      { id: UserClass.SpecialEvents, name: localization.toLanguageStringF('parkingPolicy.class.SpecialEvents'), group: 'Purpose' },
      { id: UserClass.Taxi, name: localization.toLanguageStringF('parkingPolicy.class.Taxi'), group: 'Purpose' },
      { id: UserClass.Utilities, name: localization.toLanguageStringF('parkingPolicy.class.Utilities'), group: 'Purpose' },
      { id: UserClass.Vending, name: localization.toLanguageStringF('parkingPolicy.class.Vending'), group: 'Purpose' },
      { id: UserClass.WasteManagement, name: localization.toLanguageStringF('parkingPolicy.class.WasteManagement'), group: 'Purpose' },
      { id: UserClass.Evie, name: localization.toLanguageStringF('parkingPolicy.class.Evie'), group: 'Purpose' },
      { id: UserClass.Commercial, name: localization.toLanguageStringF('parkingPolicy.class.Commercial'), group: 'Purpose' },
      { id: UserClass.Police, name: localization.toLanguageStringF('parkingPolicy.class.Police'), group: 'Purpose' },
    ]);
  }, [localization]);

  return items;
};
