import { AxiosResponse } from 'axios';
import { getAssetsApi } from '../api';

//attachment; filename=policies.zip => policies.zip
const getFileName = (response: AxiosResponse) => {
  const parts = response.headers['content-disposition']?.split('filename=');
  return parts && parts.length > 1 ? parts[1].split(';')[0] : undefined;
};

const get = async (url: string): Promise<{ data: Blob; fileName: string }> => {
  const api = await getAssetsApi();
  return api.get(url, { responseType: 'blob' }).then((response: AxiosResponse) => ({
    data: response.data,
    fileName: getFileName(response) || url.split('/').pop() || 'file',
  }));
};

export const files = { get };
