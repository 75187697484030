import React, { FC, useState } from 'react';
import { useExtendedLocalization } from '../../../hooks/use-extended-localization-service';

interface TruncatedListProps<T> {
  items: T[];
  itemKey: (item: T) => string | number;
  renderItem: (item: T, index: number, items: T[]) => React.ReactNode;
  maxLength?: number;
  getItemLength?: (item: T) => number;
  containerClassName?: string;
  toggleButtonClassName?: string;
}

export const TruncatedList: FC<TruncatedListProps<any>> = ({
  items,
  itemKey,
  renderItem,
  maxLength = 300,
  getItemLength = (item) => item?.Name?.length ?? 0,
  containerClassName,
  toggleButtonClassName,
}) => {
  const localization = useExtendedLocalization();
  const [expanded, setExpanded] = useState(false);
  const truncatedCount = getTruncatedItemsCount(items, maxLength, getItemLength);
  const shouldTruncate = truncatedCount < items.length;

  if (!shouldTruncate || expanded) {
    return (
      <>
        <div className={containerClassName}>
          {items.map((item, i) => (
            <span key={itemKey(item)}>
              {renderItem(item, i, items)}
              {i < items.length - 1 && ', '}
            </span>
          ))}
        </div>
        {shouldTruncate && (
          <button
            className={toggleButtonClassName}
            onClick={() => setExpanded(false)}
            aria-expanded='true'
            aria-label={localization.toLanguageStringF('common.truncatedList.showLessAreaLabel')}
          >
            {localization.toLanguageStringF('common.truncatedList.showLess')}
          </button>
        )}
      </>
    );
  }

  const truncatedItems = items.slice(0, truncatedCount);

  return (
    <>
      <div className={containerClassName}>
        {truncatedItems.map((item, i) => (
          <span key={itemKey(item)}>
            {renderItem(item, i, truncatedItems)}
            {i < truncatedItems.length - 1 && ', '}
            {i === truncatedItems.length - 1 && '...'}
          </span>
        ))}
      </div>
      <button
        className={toggleButtonClassName}
        onClick={() => setExpanded(true)}
        aria-expanded='false'
        aria-label={localization.toLanguageStringF('common.truncatedList.showMoreAreaLabel')}
      >
        {localization.toLanguageStringF('common.truncatedList.showMore')}
      </button>
    </>
  );
};

function getTruncatedItemsCount<T>(items: T[], maxLength: number, getItemLength: (item: T) => number): number {
  let accumulated = 0;

  const truncateIndex = items.findIndex((item) => {
    accumulated += getItemLength(item);
    return accumulated > maxLength;
  });

  return truncateIndex === -1 ? items.length : truncateIndex;
}
