import { FC, ReactNode, useCallback, useEffect, useState, Children, ReactElement } from 'react';

import { Dialog, DialogActionsBar, DialogCloseEvent } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { wrenchIcon } from '@progress/kendo-svg-icons';
import { GridColumnProps } from '@progress/kendo-react-grid';

import styles from './KendoGridColumnsSelector.module.scss';
import { Checkbox } from '@progress/kendo-react-inputs';

interface IProps {
  name: string;
  children: ReactNode;
  onChanged?: (columns: GridColumnProps[]) => void;
}

export const KendoGridColumnsSelector: FC<IProps> = ({ name, children, onChanged }) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<{ key: number; column: GridColumnProps }>>([]);
  const [selected, setSelected] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    const items = Children.toArray(children).map((x, idx) => ({ key: idx, column: (x as ReactElement)?.props as GridColumnProps }));

    const saved = localStorage.getItem(getStorageKey(name));
    let parsed: { [key: number]: boolean } = {};
    if (saved) {
      parsed = JSON.parse(saved);
    } else {
      items.forEach((x) => (parsed[x.key] = true));
    }
    setSelected(parsed);
    setColumns(items);
  }, [children, name]);

  useEffect(() => {
    if (columns.length > 0 && !initialized) {
      setInitialized(true);
      onChanged && onChanged(columns.filter((x) => selected[x.key]).map((x) => x.column));
    }
  }, [columns, initialized, onChanged, selected]);

  const openDialog = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setVisibleDialog(true);
  };

  const closeDialog = (e: React.MouseEvent<HTMLElement> | DialogCloseEvent) => {
    const event = e as any;
    event.preventDefault && event.preventDefault();

    setVisibleDialog(false);
  };

  const onSubmit = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      onChanged && onChanged(columns.filter((x) => selected[x.key]).map((x) => x.column));
      setVisibleDialog(false);
      localStorage.setItem(getStorageKey(name), JSON.stringify(selected));
    },
    [columns, onChanged, selected, name],
  );

  return (
    <>
      <Button svgIcon={wrenchIcon} onClick={openDialog}>
        Manage Fields
      </Button>

      {visibleDialog && (
        <Dialog title='Manage Fields' onClose={closeDialog} className={styles.dialog}>
          <div className={styles.legend}>Choose which fields to display.</div>

          <div className={`form-card  ${styles.formCard}`}>
            <div className={styles.fieldsHeader}>Fields</div>

            <div className={styles.rows}>
              {columns.map((x, idx) => (
                <div className={styles.row} key={`column-${x.key}`}>
                  <div className={styles.checkboxLabel}>{x.column.title}</div>
                  <Checkbox
                    className={styles.checkbox}
                    value={selected[x.key] || false}
                    onChange={(e) => {
                      selected[x.key] = e.value;
                      setSelected({ ...selected });
                    }}
                  />
                </div>
              ))}
            </div>

            <DialogActionsBar layout='end'>
              <Button className='k-button-solid-primary k-rounded-md' onClick={onSubmit}>
                <span className='k-button-text'>Apply</span>
              </Button>

              <button className='k-button k-button-md k-button-solid k-rounded-md' onClick={closeDialog}>
                <span className='k-button-text'>Cancel</span>
              </button>
            </DialogActionsBar>
          </div>
        </Dialog>
      )}
    </>
  );
};

function getStorageKey(name: string): string {
  return `${name}-columns`;
}
