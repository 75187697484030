import { getApi, getAiVideosApi } from './api';
import {
  AiVideoCameraStatus,
  IAiVideoCameraStatusInfo,
  ICamera,
  ICameraState,
  ICameraStatusCount,
  ICameraWithSpots,
  ILookupValue,
} from '../../model';
import { Utils } from '../../utils';
import { AxiosError } from 'axios';

const BASE_URL = '/camera';
const setStateAllowedErrors = [404];

interface IAiVideoCameraStatusInfoInSnakeCase {
  status: AiVideoCameraStatus;
  last_frame_time?: string;
  last_update_time?: string;
}

const get = (id: string): Promise<ICamera> => {
  return getApi()
    .get<ICamera>(`${BASE_URL}/${id}`)
    .then((response) => response.data)
    .then((x) => ({
      ...x,
      CreatedAt: new Date(x.CreatedAt),
      UpdatedAd: new Date(x.UpdatedAt),
      Address: Utils.capitalize(x.Address),
      Configuration: x.Configuration
        ? {
            ...x.Configuration,
            Mode: Utils.capitalize(x.Configuration.Mode),
            BootTime: x.Configuration.BootTime?.map((bt) => {
              const from = bt.From.split(':');
              const to = bt.To.split(':');
              return {
                From: `${from[0]}:${from[1]}`,
                To: `${to[0]}:${to[1]}`,
              };
            }),
          }
        : undefined,
    }));
};

const getState = (id: string): Promise<ICameraState | null> => {
  return getApi({ ignoreErrorStatuses: setStateAllowedErrors })
    .get<ICameraState>(`${BASE_URL}/${id}/state`)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      if (setStateAllowedErrors.includes(error.response?.status || 0)) {
        return null;
      }
      throw error;
    });
};

const getCameraBySpotsIds = (spotsIds: number[]) => {
  const ids = spotsIds.map((x) => `id=${x}`).join('&');
  return getApi()
    .get<ICameraWithSpots[]>(`${BASE_URL}/by-spots/?${ids}`)
    .then((x) => x.data || []);
};

const getStatusCount = (): Promise<ICameraStatusCount[]> =>
  getApi()
    .get<ICameraStatusCount[]>(`${BASE_URL}/status-count`)
    .then((x) => x.data || []);

const getStatusInfo = async (id: string): Promise<IAiVideoCameraStatusInfo | null> =>
  (await getAiVideosApi())
    .get<IAiVideoCameraStatusInfoInSnakeCase>(`/status/${id}`)
    .then((r) => {
      if (!r.data) return null;

      // status response expected Date type, but it can be "None" as string
      let last_frame_time: Date | null = null;
      if (r.data.last_frame_time && !isNaN(Date.parse(r.data.last_frame_time))) {
        last_frame_time = new Date(r.data.last_frame_time);
      }

      return {
        Status: r.data.status,
        LastFrameTime: last_frame_time,
      } as IAiVideoCameraStatusInfo;
    })
    .catch((_: AxiosError) => {
      return null;
    });

const getCamerasByZone = (zoneId: number) =>
  getApi()
    .get<ILookupValue<string>[]>(`${BASE_URL}/by-zone?id=${zoneId}`)
    .then((x) => x.data || []);

const getCamerasByOffstreetZone = (offstreetZoneId: number) =>
  getApi()
    .get<ILookupValue<string>[]>(`${BASE_URL}/by-offstreet-zone?id=${offstreetZoneId}`)
    .then((x) => x.data || []);

export const cameras = {
  get,
  getState,
  getCameraBySpotsIds,
  getStatusCount,
  getStatusInfo,
  getCamerasByZone,
  getCamerasByOffstreetZone,
};
