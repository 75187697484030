import { FunctionComponent as FC } from 'react';
import classNames from 'classnames/bind';

import styles from './OffstreetOccupancyMore80Icon.module.scss';

const cx = classNames.bind(styles);

interface Props {
  className?: string;
}

export const OffstreetOccupancyMore80Icon: FC<Props> = ({ className }) => {
  return <span className={cx(className, 'icon')}></span>;
};
