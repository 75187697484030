import { DateTimePicker, DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error, Hint } from '@progress/kendo-react-labels';

export const FormDateTimepicker = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, disabled, optional, required, hint, value, visited, modified, ...others } =
    fieldRenderProps;

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';

  const val = value !== undefined ? value : null;

  const handleChange = (event: DateTimePickerChangeEvent) => {
    const evt = { target: this, value: event.value };
    fieldRenderProps.onChange(evt);
    others.onChange && others.onChange(evt);
  };

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} editorDisabled={disabled} className={`${required ? 'required' : null}`}>
        {label}
      </Label>
      <div className='k-form-field-wrap'>
        <DateTimePicker
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          value={val}
          onChange={handleChange}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId} className='error'>
            {validationMessage}
          </Error>
        )}
      </div>
    </FieldWrapper>
  );
};
