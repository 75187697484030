import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { IParkingEvent, ISelectedCamera } from '../../../../model';
import { ICameraActivityStatusChangeDto, ISpotOccupancyStatusChangeDto } from '../../../../services/signaling';
import { RootState, store } from '../../../../store';
import { ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedCameraState {
  selected: ISelectedEntity<ISelectedCamera, string>[];
  current: string | null;
}

const initialState: ISelectedCameraState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedCameras',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.Cameras,
        initialState,
        (id) => id,
        (selected) => {
          selected.map((x) => store.dispatch(selectedCamerasActions.loadCamera(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: string; position: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.Cameras, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.Cameras);
    },
    loadCamera(state, action: PayloadAction<{ id: string; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadCameraSuccess(state, action: PayloadAction<{ camera: ISelectedCamera; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.camera.Id,
        action.payload.camera,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadCameraFailed(state, action: PayloadAction<{ id: string; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    closePopup(state, action: PayloadAction<string | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.Cameras, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.Cameras);
    },
    openPopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<string>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.Cameras, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedCamera | null>) {
      state.current = action.payload?.Id || null;
    },
    changeCameraState(state, action: PayloadAction<ICameraActivityStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.state && item.entity.Id === action.payload.id) {
          item.entity.state.Status = action.payload.status;
        }
      });
    },
    changeCurbSpaceState(state, action: PayloadAction<ISpotOccupancyStatusChangeDto>) {
      state.selected.forEach((item) => {
        if (item.entity?.spotsStates) {
          const curbSpace = item.entity.spotsStates.find((x) => x.SpotId === action.payload.id);
          if (curbSpace) {
            curbSpace.CompanyName = action.payload.companyName;
            curbSpace.Status = action.payload.status;
          }
        }
      });
    },

    fetchEvents(state, action: PayloadAction<string>) {},
    fetchEventsSuccess(state, action: PayloadAction<{ cameraId: string; events: IParkingEvent[] }>) {
      const item = state.selected.find((x) => x.entity?.Id === action.payload.cameraId);
      if (!item?.entity) {
        return;
      }
      item.entity.events = action.payload.events;
    },
    fetchEventsFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
  },
});

// Actions
export const selectedCamerasActions = slice.actions;

// Selectors
export const selectedCameras = (state: RootState) => state.selectedCameras;
export const currentCamera = (state: RootState) => state.selectedCameras.selected.find((x) => x.id === state.selectedCameras.current);

// Reducer
export const selectedCamerasReducer = slice.reducer;
