import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { geoProcessor, revenueData } from '../../../../services';
import { revenueBlockfaceGeoActions } from './revenue-blockface-geo-slice';
import { revenueMeterGeoActions } from './revenue-meter-geo-slice';
import { revenueOffstreetZoneGeoActions } from './revenue-offstreet-zone-geo-slice';
import { revenueDataActions } from './revenue-slice';
import { revenueZoneGeoActions } from './revenue-zone-geo-slice';


const fetchMeterRevenueEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueMeterGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadMetersRevenue(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => revenueMeterGeoActions.fetchSuccess(x)),
          catchError((err) => of(revenueMeterGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const fetchZoneRevenueEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueZoneGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadZonesRevenue(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => revenueZoneGeoActions.fetchSuccess(x)),
          catchError((err) => of(revenueZoneGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const fetchOffstreetZoneRevenueEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueOffstreetZoneGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadOffstreetZonesRevenue(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => revenueOffstreetZoneGeoActions.fetchSuccess(x)),
          catchError((err) => of(revenueOffstreetZoneGeoActions.fetchFailed(err.message))),
        ),
    ),
  );



const fetchBlockfaceRevenueEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueBlockfaceGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadBlockfacesRevenue(
          action.payload.box,
          action.payload.box.zoom,
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesMin,
          action.payload.minutesMax,
        )
        .pipe(
          map((x) => revenueBlockfaceGeoActions.fetchSuccess(x)),
          catchError((err) => of(revenueBlockfaceGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const fetchStudyAreaRevenueDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueDataActions.fetchStudyAreaRevenueData.match),
    switchMap((action) =>
      from(
        revenueData.getStudyAreaRevenueHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => revenueDataActions.fetchStudyAreaRevenueDataSuccess(x)),
        catchError((err) => of(revenueDataActions.fetchStudyAreaRevenueDataFailed(err.message))),
      ),
    ),
  );


const fetchSpotRevenueHeatmapDataEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(revenueDataActions.fetchSpotRevenueHeatmapData.match),
    switchMap((action) =>
      from(
        revenueData.getSpotRevenueHeatmapData(
          action.payload.period,
          action.payload.weekDays,
          action.payload.minutesStart,
          action.payload.minutesEnd,
        ),
      ).pipe(
        map((x) => revenueDataActions.fetchSpotRevenueHeatmapDataSuccess(x)),
        catchError((err) => of(revenueDataActions.fetchSpotRevenueHeatmapDataFailed(err.message))),
      ),
    ),
  );

export const revenueEpic = combineEpics(
  fetchMeterRevenueEpic,
  fetchZoneRevenueEpic,
  fetchOffstreetZoneRevenueEpic,
  fetchBlockfaceRevenueEpic,
  fetchStudyAreaRevenueDataEpic,
  fetchSpotRevenueHeatmapDataEpic,
);
