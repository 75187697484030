import { FC, useState, useEffect, useMemo } from 'react';
import { TextBox } from '@progress/kendo-react-inputs';
import { debounceTime, Subject } from 'rxjs';

import styles from './DebounceSearchTextBox.module.scss';
import { Button } from '@progress/kendo-react-buttons';

interface IProps {
  onChange: (value?: string) => void;
  className?: string;
  placeholder?: string;
  dueTimeMs?: number;
}

export const DebounceSearchTextBox: FC<IProps> = ({ onChange, className, placeholder = 'Search', dueTimeMs = 800 }) => {
  const [text, setText] = useState<string>();

  const onSearch$ = useMemo(() => new Subject<string | undefined>(), []);

  useEffect(() => {
    const subscription = onSearch$.pipe(debounceTime(dueTimeMs)).subscribe(onChange);
    return () => subscription.unsubscribe();
  }, [onSearch$, dueTimeMs, onChange]);

  return (
    <div className={`${styles.container}`}>
      <TextBox
        className={`${styles.textBox} ${className || ''}`}
        value={text}
        onChange={(event) => {
          const value = event.value?.toString();
          setText(value);
          onSearch$.next(value);
        }}
        placeholder={placeholder}
      />

      <Button
        icon='x'
        size='small'
        className={styles.clear}
        onClick={(e) => {
          setText('');
          onChange && onChange('');
        }}
      />
    </div>
  );
};
