import { useEffect, useState } from 'react';
import { Activity } from '../../model';
import { useExtendedLocalization } from '../use-extended-localization-service';

export const useActivities = () => {
  const localization = useExtendedLocalization();
  const [items, setItems] = useState<Array<{ id: Activity; name: string }>>([]);

  useEffect(() => {
    setItems([
      { id: Activity.Parking, name: localization.toLanguageStringF('parkingPolicy.activity.Parking') },
      { id: Activity.NoParking, name: localization.toLanguageStringF('parkingPolicy.activity.NoParking') },
      { id: Activity.Loading, name: localization.toLanguageStringF('parkingPolicy.activity.Loading') },
      { id: Activity.NoLoading, name: localization.toLanguageStringF('parkingPolicy.activity.NoLoading') },
      { id: Activity.Unloading, name: localization.toLanguageStringF('parkingPolicy.activity.Unloading') },
      { id: Activity.NoUnloading, name: localization.toLanguageStringF('parkingPolicy.activity.NoUnloading') },
      { id: Activity.Stopping, name: localization.toLanguageStringF('parkingPolicy.activity.Stopping') },
      { id: Activity.NoStopping, name: localization.toLanguageStringF('parkingPolicy.activity.NoStopping') },
      { id: Activity.Travel, name: localization.toLanguageStringF('parkingPolicy.activity.Travel') },
      { id: Activity.NoTravel, name: localization.toLanguageStringF('parkingPolicy.activity.NoTravel') },
    ]);
  }, [localization]);

  return items;
};
