import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { IOccupancySourceReport, IOffstreetZoneRevenueReportItem, IRevenueItem, ISelectedOffstreetZone, OccupancySource } from '../../../../model';
import { IReportFilter, ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { RootState, store } from '../../../../store';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedOffstreetZoneState {
  selected: ISelectedEntity<ISelectedOffstreetZone>[];
  current: number | null;
}

const initialState: ISelectedOffstreetZoneState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedOffstreetZones',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.OffstreetZones,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedOffstreetZonesActions.loadZone(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.OffstreetZones, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.OffstreetZones);
    },
    loadZone(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadZoneSuccess(state, action: PayloadAction<{ zone: ISelectedOffstreetZone; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.zone.Id,
        action.payload.zone,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadZoneFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.OffstreetZones, action.payload);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.OffstreetZones, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedOffstreetZone | null>) {
      state.current = action.payload?.Id || null;
    },
    moveToZone(state, action: PayloadAction<{ id: number; position: Position | null }>) { },
    moveToZoneFailed(state, action: PayloadAction<string>) { },

    fetchRevenueReport(state, action: PayloadAction<{ zoneId: number; filter: IReportFilter }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchRevenueReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IOffstreetZoneRevenueReportItem | null }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;

      if (!zone.entity) {
        return;
      }
      zone.entity.revenueReport = action.payload.report;
    },
    fetchRevenueReportFailed(state, action: PayloadAction<{ zoneId: number; error: string }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      console.error(action.payload.error);
    },

    fetchRevenue(state, action: PayloadAction<number>) { },
    fetchRevenueSuccess(state, action: PayloadAction<{ zoneId: number; revenue: IRevenueItem[] }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.zoneId);
      if (!zone?.entity) {
        return;
      }
      zone.entity.revenue = action.payload.revenue;
    },
    fetchRevenueFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },


    fetchOccupancySourceReport(state, action: PayloadAction<{ offstreetZoneId: number; period: [Date, Date]; occupancySource: OccupancySource }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.offstreetZoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchOccupancySourceReportSuccess(state, action: PayloadAction<{ offstreetZoneId: number; report: IOccupancySourceReport | null }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.offstreetZoneId);
      if (!zone?.entity) {
        return;
      }
      zone.loading = false;
      zone.entity.occupancySourceReport = action.payload.report;
    },
    fetchOccupancySourceReportFailed(state, action: PayloadAction<{ offstreetZoneId: number; error: string }>) {
      const zone = state.selected.find((x) => x.entity?.Id === action.payload.offstreetZoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      console.log(action.payload);
    },
  },
});

// Actions
export const selectedOffstreetZonesActions = slice.actions;

// Selectors
export const selectedOffstreetZones = (state: RootState) => state.selectedOffstreetZones;
export const currentOffstreetZone = (state: RootState) =>
  state.selectedOffstreetZones.selected.find((x) => x.id === state.selectedOffstreetZones.current);

// Reducer
export const selectedOffstreetZonesReducer = slice.reducer;
