import { Action } from '@reduxjs/toolkit';
import { combineEpics, StateObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap } from 'rxjs/operators';

import { offstreetZones, reports } from '../../../../services';
import { RootState } from '../../../../store';
import { citiesActions } from '../../../common';
import { mapStateActions } from '../../map-state';
import { selectedOffstreetZoneHeatmapsActions } from './selected-offstreet-zone-heatmaps-slice';
import { selectedZoneHeatmapsActions } from './selected-zone-heatmaps-slice';

const loadOffstreetZone = (id: number, state: RootState) => {
  const existing = state.selectedOffstreetZoneHeatmaps.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return from(offstreetZones.get(id)).pipe(
    map((zone) => ({
      offstreetZone: zone
    })),
  );
};


const revenueOffstreetZoneSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedOffstreetZoneHeatmapsActions.loadOffstreetZoneHeatmap.match),
    concatMap((action) =>
      loadOffstreetZone(action.payload.id, state$.value).pipe(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mergeMap((x) =>
          of(
            selectedOffstreetZoneHeatmapsActions.loadOffstreetZoneHeatmapSuccess({
              zone: x,
              position: action.payload.position!,
              initPosition: action.payload.initPosition ? action.payload.initPosition : action.payload.position!,
            }),
          ),
        ),
        catchError((err) => of(selectedOffstreetZoneHeatmapsActions.loadOffstreetZoneHeatmapFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    mergeMap((x) => of(selectedOffstreetZoneHeatmapsActions.closePopup(), selectedZoneHeatmapsActions.closePopup())),
  );


const fetchRevenueReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedOffstreetZoneHeatmapsActions.fetchRevenueReport.match),
    concatMap((action) => {
      return from(reports.getOffstreetZoneRevenueReport(action.payload.zoneId, action.payload.filter)).pipe(
        map((x) => selectedOffstreetZoneHeatmapsActions.fetchRevenueReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedOffstreetZoneHeatmapsActions.fetchRevenueReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyReportDataEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedOffstreetZoneHeatmapsActions.fetchOccupancyReport.match),
    concatMap((action) =>
      from(reports.getOffstreetZoneOccupancySourceReport(action.payload.zoneId, action.payload.occupancySource, action.payload.period)).pipe(
        map((x) => selectedOffstreetZoneHeatmapsActions.fetchOccupancyReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedOffstreetZoneHeatmapsActions.fetchOccupancyReportFailed(err.message))),
      ),
    ),
  );
};



const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap(() => of(selectedOffstreetZoneHeatmapsActions.collapsePopups())),
  );

export const offstreetZoneHeatmapEpic = combineEpics(
  revenueOffstreetZoneSelectedEpic,
  closePopupsEpic,
  fetchRevenueReportEpic,
  citySelectedEpic,
  fetchOccupancyReportDataEpic,
);
