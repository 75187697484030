import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { getAssetsApi } from '../api';
import { IPolicy, IPolicyFilter, IPolicyItem, IPolicyUpsert, IZonePolicy } from '../../../model/api/assets/policy';
import { IPaginationResponse, IPolicyLookupValue, ZoneType } from '../../../model/api/assets';
import { TimeSpans } from '../../../model/api/policy';

const BASE_URL = '/policies';

const listByZone = async (zoneId: number, type: ZoneType) => {
  const api = await getAssetsApi();
  return api
    .get<IPolicyItem[]>(`${BASE_URL}/by-zone?id=${zoneId}&type=${type}`)
    .then((response) => response.data || [])
    .then((x) => {
      x.map((policy) => fixDates(policy));
      return x;
    });
};

const list = async (dataState: DataSourceRequestState, filter: IPolicyFilter) => {
  const querySegments: Array<string> = [];

  if (filter.query) {
    querySegments.push(`query=${filter.query}`);
  }

  const queryStr = toDataSourceRequestString(dataState);
  if (queryStr) {
    querySegments.push(queryStr);
  }

  const url = `${BASE_URL}?${querySegments.join('&')}`;

  const api = await getAssetsApi();
  return api
    .get<IPaginationResponse<IPolicyItem>>(url)
    .then((response) => response.data)
    .then((res) => {
      res.Data.forEach((x) => {
        fixDates(x);
      });
      return res;
    });
};

const get = async (id: number) => {
  const api = await getAssetsApi();
  return api
    .get<IPolicy>(`${BASE_URL}/${id}`)
    .then((response) => response.data)
    .then((res) => {
      res.PublishedDate = new Date(res.PublishedDate);
      fixTimespansDates(res.TimeSpans);
      return res;
    });
};

const listZonePolicies = async (policyId: number) => {
  const api = await getAssetsApi();
  return api
    .get<IZonePolicy[]>(`${BASE_URL}/${policyId}/zones`)
    .then((response) => response.data || [])
    .then((res) => {
      res.forEach((x) => {
        x.StartDate = new Date(x.StartDate);
        if (x.EndDate) {
          x.EndDate = new Date(x.EndDate);
        }
      });
      return res;
    });
};

const fixDates = (policy: IPolicyItem) => {
  policy.PublishedDate = new Date(policy.PublishedDate);
  if (policy.StartDate) {
    policy.StartDate = new Date(policy.StartDate);
  }
  if (policy.EndDate) {
    policy.EndDate = new Date(policy.EndDate);
  }
  fixTimespansDates(policy.TimeSpans);
};

const fixTimespansDates = (timeSpans: TimeSpans[] | null) => {
  if (timeSpans) {
    timeSpans.forEach((x) => {
      if (x.StartDate) {
        x.StartDate = new Date(x.StartDate);
      }
      if (x.EndDate) {
        x.EndDate = new Date(x.EndDate);
      }
    });
  }
};

const update = async (id: number, policy: IPolicyUpsert): Promise<void> => {
  const api = await getAssetsApi();
  return api.put(`${BASE_URL}/${id}`, policy);
};

const assignZones = async (policyId: number, zones: Array<{ Id: number; Type: ZoneType }>, startDate: Date): Promise<void> => {
  const api = await getAssetsApi();
  return api.post(`${BASE_URL}/${policyId}/assign-zones`, { zones: zones, startDate: startDate });
};

const create = async (policy: IPolicyUpsert): Promise<void> => {
  const api = await getAssetsApi();
  return api.post(BASE_URL, policy);
};

const revokeZones = async (policyId: number, zones: Array<{ Id: number; Type: ZoneType }>): Promise<void> => {
  const api = await getAssetsApi();
  return api.post(`${BASE_URL}/${policyId}/revoke-zones`, { zones: zones });
};

const deleteZones = async (policyId: number, zones: Array<{ Id: number; Type: ZoneType }>): Promise<void> => {
  const api = await getAssetsApi();
  return api.post(`${BASE_URL}/${policyId}/delete-zones`, { zones: zones });
};

const deletePolicy = async (policyId: number): Promise<void> => {
  const api = await getAssetsApi();
  return api.delete(`${BASE_URL}/${policyId}`);
};

const getLookupValues = async (name: string | undefined): Promise<IPolicyLookupValue[]> => {
  const api = await getAssetsApi();
  return api.get<IPolicyLookupValue[]>(`${BASE_URL}/lookup-values?name=${name}`).then((response) => response.data);
};

export const policiesService = {
  listByZone,
  list,
  get,
  listZonePolicies,
  create,
  update,
  deletePolicy,
  assignZones,
  revokeZones,
  deleteZones,
  getLookupValues,
};
