import { useCallback, useEffect, useRef, useState } from 'react';

import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Hint, Label, Error } from '@progress/kendo-react-labels';
import { filterBy } from '@progress/kendo-data-query';

export const FormMultiselect = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    optional,
    required,
    value,
    valueMap,
    parseValue,
    data,
    onChange,
    ...others
  } = fieldRenderProps;
  const editorRef = useRef(null);
  const [items, setItems] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const labelId: string = label ? `${id}_label` : '';

  useEffect(() => {
    setItems(data);
  }, [data]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: MultiSelectChangeEvent) => {
    if (event.value) {
      event.value.forEach((x: any) => {
        if (selectedItems.filter((i) => i === x).length === 0) {
          selectedItems.push(x);
        }
      });
      setSelectedItems([...selectedItems]);
    }

    event.value = valueMap && event.value ? event.value.map((x: any) => valueMap(x)) : event.value;
    onChange && onChange(event);
  };

  const [valueItem, setValueItem] = useState();
  useEffect(() => {
    let newValue = value;

    if (valueMap && value) {
      const allItems = items.concat(selectedItems);
      newValue = value.map((x: any) => (allItems || []).find((i: any) => valueMap(i) === x)).filter((x: any) => x);
    }

    if (parseValue) {
      newValue = parseValue(value);
    }

    setValueItem(newValue);
  }, [items, parseValue, selectedItems, value, valueMap]);

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className={`${required ? 'required' : null}`}
      >
        {label}
      </Label>
      <div className={'k-form-field-wrap'}>
        <MultiSelect
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          data={data}
          {...others}
          value={valueItem}
          onChange={handleChange}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};
