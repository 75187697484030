import { AxiosError } from 'axios';
import {
  ICityOverviewReport,
  IOccupancySourceReport,
  IOffstreetZoneRevenueReportItem,
  IParkingDurationReportItem,
  ITrafficReportItem,
  IViolationInfo,
  IViolationMetric,
  IZoneRevenueReportItem,
  Interval,
  Period,
  ViolationMetricPeriod,
} from '../../model';
import { IOccupancyReportItem, IOccupancyTrafficReport, OccupancySource } from '../../model/api/occupancy';
import { IKpiReportItem } from '../../model/api/zone';
import { dateUtils } from '../../utils';
import { getApi } from './api';

const BASE_URL = '/report';
const allowedErrors = [404];

export enum PeriodTypeNumeric {
  Day = 1,
  Week = 2,
  Month = 3,
  Quarter = 4,
  Yesterday = 5,
}

export interface IReportFilter {
  period: [Date, Date];
  prevPeriod: [Date, Date];
  interval?: Interval;
}

const filterToQueryStr = (filter: IReportFilter): string => {
  return `period.from=${dateUtils.toDateTimeString(filter.period[0])}&period.to=${dateUtils.toDateTimeString(
    filter.period[1],
  )}&prevperiod.from=${dateUtils.toDateTimeString(filter.prevPeriod[0])}&prevperiod.to=${dateUtils.toDateTimeString(filter.prevPeriod[1])}${
    filter.interval ? `&interval=${filter.interval}` : ''
  }`;
};

const getKpiReport = (query: string): Promise<Array<IKpiReportItem>> => {
  return getApi()
    .get<Array<IKpiReportItem>>(`${BASE_URL}/${query}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, PeriodId: PeriodTypeNumeric[x.PeriodId as unknown as number] as Period };
      }),
    );
};

const getOcupancySourceReport = (query: string, period: [Date, Date]): Promise<IOccupancySourceReport | null> => {
  const periodQuery = `from=${dateUtils.toDateTimeString(period[0])}&to=${dateUtils.toDateTimeString(period[1])}`;
  return getApi()
    .get<IOccupancySourceReport>(`${BASE_URL}/${query}?${periodQuery}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getZoneRevenueReport = (zoneId: number, filter: IReportFilter): Promise<IZoneRevenueReportItem | null> => {
  return getApi()
    .get<IZoneRevenueReportItem>(`${BASE_URL}/zone-revenue-custom/${zoneId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getZoneOccupancyTrafficReport = (
  zoneId: number,
  filter: IReportFilter,
  occupancySource: OccupancySource,
): Promise<IOccupancyTrafficReport | null> =>
  getApi()
    .get<IOccupancyTrafficReport>(`${BASE_URL}/zone-occupancy-traffic/${zoneId}/${occupancySource}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));

const getBlockfaceOccupancyTrafficReport = (
  blockfaceId: number,
  filter: IReportFilter,
  occupancySource: OccupancySource,
): Promise<IOccupancyTrafficReport | null> =>
  getApi()
    .get<IOccupancyTrafficReport>(`${BASE_URL}/blockface-occupancy-traffic/${blockfaceId}/${occupancySource}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));

const getZoneKpiReport = (zoneId: number) => getKpiReport(`zone-kpi/${zoneId}`);

const getOffstreetZoneRevenueReport = (zoneId: number, filter: IReportFilter): Promise<IOffstreetZoneRevenueReportItem | null> => {
  return getApi()
    .get<IOffstreetZoneRevenueReportItem>(`${BASE_URL}/offstreet-zone-revenue/${zoneId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getCityOverviewReport = (): Promise<ICityOverviewReport | null> => {
  return getApi({ ignoreErrorStatuses: allowedErrors })
    .get<ICityOverviewReport>(`${BASE_URL}/city-overview`)
    .then((res) => {
      res.data.DailyRevenueChartData.forEach((x) => {
        x.Date = new Date(x.Date);
      });

      return res.data;
    })
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });
};

const getParkingDurationReport = (zoneId: number, filter: IReportFilter): Promise<IParkingDurationReportItem | null> => {
  return getApi()
    .get<IParkingDurationReportItem>(`${BASE_URL}/zone-parking-duration/${zoneId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getZoneEnforcementReport = (zoneId: number, filter: IReportFilter): Promise<IViolationInfo | null> =>
  getApi({ ignoreErrorStatuses: allowedErrors })
    .get<IViolationInfo>(`${BASE_URL}/zone-enforcement/${zoneId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null))
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });

const getBlockfaceEnforcementReport = (blockfaceId: number, filter: IReportFilter): Promise<IViolationInfo | null> =>
  getApi({ ignoreErrorStatuses: allowedErrors })
    .get<IViolationInfo>(`${BASE_URL}/blockface-enforcement/${blockfaceId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null))
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });

const getStudyAreaEnforcementReport = (studyAreaId: number, filter: IReportFilter): Promise<IViolationInfo | null> =>
  getApi({ ignoreErrorStatuses: allowedErrors })
    .get<IViolationInfo>(`${BASE_URL}/study-area-enforcement/${studyAreaId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null))
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });

const getZoneMetricReport = (zoneId: number): Promise<IViolationMetric[]> =>
  getApi({ ignoreErrorStatuses: allowedErrors })
    .get<IViolationMetric[]>(`${BASE_URL}/zone-enforcement-metric/${zoneId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return {
          ...x,
          Period: PeriodTypeNumeric[x.Period as unknown as number] as ViolationMetricPeriod,
        };
      }),
    )
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return [];
      }
      throw err;
    });

const getBlockfaceRevenueReport = (blockfaceId: number, filter: IReportFilter): Promise<IZoneRevenueReportItem | null> => {
  return getApi()
    .get<IZoneRevenueReportItem>(`${BASE_URL}/blockface-revenue/${blockfaceId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getZoneTrafficReport = (zoneId: number): Promise<Array<ITrafficReportItem>> => {
  return getApi()
    .get<Array<ITrafficReportItem>>(`${BASE_URL}/zone-traffic/${zoneId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );
};

const getBlockfaceTrafficReport = (blockfaceId: number): Promise<Array<ITrafficReportItem>> => {
  return getApi()
    .get<Array<ITrafficReportItem>>(`${BASE_URL}/blockface-traffic/${blockfaceId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );
};

const getSpotOccupancyReport = (spotId: number, occupancySource: OccupancySource): Promise<Array<IOccupancyReportItem>> =>
  getApi()
    .get<Array<IOccupancyReportItem>>(`${BASE_URL}/spot-occupancy/${spotId}/${occupancySource}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getZoneOccupancyReport = (zoneId: number, occupancySource: OccupancySource): Promise<Array<IOccupancyReportItem>> =>
  getApi()
    .get<Array<IOccupancyReportItem>>(`${BASE_URL}/zone-occupancy/${zoneId}/${occupancySource}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getBlockfaceOccupancyReport = (blockfaceId: number, occupancySource: OccupancySource): Promise<Array<IOccupancyReportItem>> =>
  getApi()
    .get<Array<IOccupancyReportItem>>(`${BASE_URL}/blockface-occupancy/${blockfaceId}/${occupancySource}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getBlockfaceParkingDurationReport = (blockfaceId: number, filter: IReportFilter): Promise<IParkingDurationReportItem | null> => {
  return getApi()
    .get<IParkingDurationReportItem>(`${BASE_URL}/blockface-parking-duration/${blockfaceId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getStudyAreaParkingDurationReport = (studyAreaId: number, filter: IReportFilter): Promise<IParkingDurationReportItem | null> => {
  return getApi()
    .get<IParkingDurationReportItem>(`${BASE_URL}/study-area-parking-duration/${studyAreaId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getStudyAreaRevenueReport = (studyAreaId: number, filter: IReportFilter): Promise<IZoneRevenueReportItem | null> => {
  return getApi()
    .get<IZoneRevenueReportItem>(`${BASE_URL}/study-area-revenue/${studyAreaId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getStudyAreaOccupancyTrafficReport = (
  studyAreaId: number,
  filter: IReportFilter,
  occupancySource: OccupancySource,
): Promise<IOccupancyTrafficReport | null> =>
  getApi()
    .get<IOccupancyTrafficReport>(`${BASE_URL}/study-area-occupancy-traffic/${studyAreaId}/${occupancySource}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));

const getStudyAreaTrafficReport = (studyAreaId: number): Promise<Array<ITrafficReportItem>> =>
  getApi()
    .get<Array<ITrafficReportItem>>(`${BASE_URL}/study-area-traffic/${studyAreaId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getStudyAreaOccupancyReport = (studyAreaId: number, occupancySource: OccupancySource): Promise<Array<IOccupancyReportItem>> =>
  getApi()
    .get<Array<IOccupancyReportItem>>(`${BASE_URL}/study-area-occupancy/${studyAreaId}/${occupancySource}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getBlockfaceKpiReport = (blockfaceId: number) => getKpiReport(`blockface-kpi/${blockfaceId}`);

const getStudyAreaKpiReport = (studyAreaId: number) => getKpiReport(`study-area-kpi/${studyAreaId}`);

const getSpotKpiReport = (spotId: number) => getKpiReport(`spot-kpi/${spotId}`);

const getZoneOccupancySourceReport = (
  zoneId: number,
  occupancySource: OccupancySource,
  period: [Date, Date],
): Promise<IOccupancySourceReport | null> => getOcupancySourceReport(`zone-occupancy-custom-period/${zoneId}/${occupancySource}`, period);

const getOffstreetZoneOccupancySourceReport = (
  zoneId: number,
  occupancySource: OccupancySource,
  period: [Date, Date],
): Promise<IOccupancySourceReport | null> => getOcupancySourceReport(`offstreet-zone-occupancy-custom-period/${zoneId}/${occupancySource}`, period);

const getBlockfaceOccupancySourceReport = (
  blockfaceId: number,
  occupancySource: OccupancySource,
  period: [Date, Date],
): Promise<IOccupancySourceReport | null> =>
  getOcupancySourceReport(`blockface-occupancy-custom-period/${blockfaceId}/${occupancySource}`, period);

const getStudyAreaOccupancySourceReport = (
  studyAreaId: number,
  occupancySource: OccupancySource,
  period: [Date, Date],
): Promise<IOccupancySourceReport | null> =>
  getOcupancySourceReport(`study-area-occupancy-custom-period/${studyAreaId}/${occupancySource}`, period);

const getSpotOccupancySourceReport = (
  spotId: number,
  occupancySource: OccupancySource,
  period: [Date, Date],
): Promise<IOccupancySourceReport | null> => getOcupancySourceReport(`spot-occupancy-custom-period/${spotId}/${occupancySource}`, period);

export const reports = {
  getZoneOccupancyTrafficReport,
  getBlockfaceOccupancyTrafficReport,
  getZoneRevenueReport,
  getZoneKpiReport,
  getOffstreetZoneRevenueReport,
  getCityOverviewReport,
  getParkingDurationReport,
  getZoneEnforcementReport,
  getBlockfaceEnforcementReport,
  getStudyAreaEnforcementReport,
  getZoneMetricReport,
  getBlockfaceRevenueReport,
  getZoneTrafficReport,
  getBlockfaceTrafficReport,
  getBlockfaceParkingDurationReport,
  getSpotOccupancyReport,
  getZoneOccupancyReport,
  getBlockfaceOccupancyReport,
  getStudyAreaRevenueReport,
  getStudyAreaOccupancyTrafficReport,
  getStudyAreaTrafficReport,
  getStudyAreaOccupancyReport,
  getBlockfaceKpiReport,
  getStudyAreaKpiReport,
  getStudyAreaParkingDurationReport,
  getSpotKpiReport,
  getZoneOccupancySourceReport,
  getBlockfaceOccupancySourceReport,
  getStudyAreaOccupancySourceReport,
  getSpotOccupancySourceReport,
  getOffstreetZoneOccupancySourceReport,
};
