import { useState } from 'react';
import { files } from '../../services';

export const useDownloadFile = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = (url: string) => {
    setIsDownloading(true);
    files
      .get(url)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = response.fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
      .finally(() => setIsDownloading(false));
  };

  return { downloadFile, isDownloading };
};
